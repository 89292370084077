<!--
 * @Author: Akko
 * @Description: 基本资格(专才)
-->
<template>
    <div>
        <div v-if="!loading">
            <ZCInfoCondition ref="third" origin="client" :model.sync="third" @handleClick="handleClick" />
        </div>
        <div class="operations-group">
            <el-button @click="goPre">上一步</el-button>
            <el-button type="primary" :loading="isSave" @click="submitForm('third',1)">保存</el-button>
            <el-button type="primary" @click="submitForm('third',2)">下一步</el-button>
        </div>
    </div>
</template>

<script lang="javascript">
import { expertThird } from "@/api/workfow.js";
import { mapActions } from "vuex";
export default {
    name: "Basics",
    components: {},
    data() {
        return {
            loading: true,
            isSave: false, //防抖loading
            third: [],
        };
    },
    created() {
        this.dataEcho();
    },
    methods: {
        ...mapActions(["expertData"]),
        // 数据回显
        dataEcho() {
            this.expertData(this.$route.params.id).then((res) => {
                this.loading = false;
                if (res.code == 200) {
                    this.third = res.data.third;
                    // 初始值填充
                    if (this.third.length === 0) {
                        res.data.second.forEach((ele) => {
                            if (
                                (ele.relation == 3 && ele.follow == 2) ||
                                ele.relation == 4
                            ) {
                                this.third.push({
                                    child_deny: { has: "", info: "" },
                                    child_departure: { has: "", info: "" },
                                    child_in_hk: { has: "", info: "" },
                                    country_identity: { has: "", info: "" },
                                    relation: ele.relation,
                                    subset_name: ele.subset_name,
                                });
                            }
                        });
                    }
                }
            });
            1;
        },
        // 内部操作事件
        handleClick(event, data) {
            console.log(event, data);
        },
        /**
         * 点击事件
         * 表单名字 formName
         * 操作 operation 1 保存 2 下一步
         */
        submitForm(formName, operation) {
            if (operation === 1) {
                // 保存
                this.isSave = true;
                this.hanledSaveQuestion(formName, "save");
            } else {
                // 下一步
                this.$nextTick(() => {
                    const { validateForm } = this.$refs[formName];
                    validateForm()
                        .then((res) => {
                            console.log(res);
                            this.hanledSaveQuestion(formName, "next");
                        })
                        .catch((err) => {
                            console.log(err, "err");
                            return this.$message.error("请完善信息");
                        });
                });
            }
        },
        // 发起保存动作
        hanledSaveQuestion(formName, txt) {
            let obj = Object.assign({
                order_id: this.$route.params.id,
                step: txt === "save" ? 0 : 3,
                data: this.$refs[formName].model,
            });
            expertThird(obj).then((res) => {
                this.isSave = false;
                if (res.code == 200) {
                    // 切换步骤保存 下一步的时候才设置
                    if (txt === "save") {
                        this.dataEcho();
                        this.$message.success("保存成功！");
                    } else {
                        this.$message.success("提交成功！");
                        this.$router.replace({
                            name: "etep4",
                        });
                    }
                }
            });
        },
        // 返回上一步
        goPre() {
            this.$router.replace({
                name: "etep2",
            });
        },
    },
};
</script>
